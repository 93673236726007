import Vue from 'vue'

import store from './store'
class Initializer {
  constructor() {
    this.vueComponents = []
    this.start()
  }

  start() {
    this.initialInstances()
  }

  initialInstances() {
    document.addEventListener('DOMContentLoaded', () => {
      let templates = document.querySelectorAll('[data-vue]')

      templates.forEach(async (template) => {
        const vueName = template.dataset.vue
        const initializer = await import(`./initializers/${vueName}.js`)

        let vueComponent = new Vue(
          Object.assign(initializer.default, {
            el: template,
            store
          })
        )

        this.vueComponents.push(vueComponent)
      })
    })
  }
}

export default Initializer
